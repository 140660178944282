import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	isLogin: boolean;
}
const Logo: FC<ILogoProps> = ({ isLogin }) => {
	return (
		<svg
			width={isLogin ? 200 : 40}
			height={isLogin ? 200 : 40}
			viewBox='0 0 1024 1024'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<rect width='1024' height='1024' rx='64' fill='#266CFF' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M322.721 284.936C316.898 284.936 312.178 289.656 312.178 295.479V514.779C312.178 520.602 316.898 525.322 322.721 525.322H472.437V418.484H552.566V525.322H702.279C708.102 525.322 712.822 520.602 712.822 514.779V295.479C712.822 289.656 708.102 284.936 702.279 284.936H322.721ZM322.721 552.033C316.898 552.033 312.178 556.754 312.178 562.576V728.457C312.178 734.28 316.898 739.001 322.721 739.001H461.892C467.715 739.001 472.436 734.28 472.436 728.457V562.576C472.436 556.754 467.715 552.033 461.892 552.033H322.721ZM552.563 562.576C552.563 556.754 557.283 552.033 563.106 552.033H702.277C708.1 552.033 712.821 556.754 712.821 562.576V728.457C712.821 734.28 708.1 739.001 702.277 739.001H563.106C557.283 739.001 552.563 734.28 552.563 728.457V562.576Z'
				fill='#DBE1FF'
			/>
		</svg>
	);
};
Logo.propTypes = {
	isLogin: PropTypes.bool.isRequired,
};

export default Logo;
