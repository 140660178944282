import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { authPagesMenu } from '../../menu';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import { UserData } from '../../common/data/user-data';
import BusinessContext from '../../contexts/businessContext';

const User = () => {
	const { width } = useWindowSize();
	const { setAsideStatus } = useContext(ThemeContext);
	const { setUser } = useContext(AuthContext);
	const { business } = useContext(BusinessContext);

	const navigate = useNavigate();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(true);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img src={business?.logo} alt='Avatar' width={128} height={128} />
				</div>
				<div className='user-info'>
					<div className='user-name'>{business?.name}</div>
				</div>
			</div>
			<NavigationLine />
			<nav aria-label='aside-bottom-user-menu-2'>
				<div className='navigation'>
					<div
						role='presentation'
						className='navigation-item cursor-pointer'
						onClick={() => {
							if (setUser) {
								setUser({} as UserData);
							}
							if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
								setAsideStatus(false);
							}
							navigate(`../${authPagesMenu.login.path}`);
						}}>
						<span className='navigation-link navigation-link-pill'>
							<span className='navigation-link-info'>
								<Icon icon='Logout' className='navigation-icon' />
								<span className='navigation-text'>Çıkış</span>
							</span>
						</span>
					</div>
				</div>
			</nav>
		</>
	);
};

export default User;
