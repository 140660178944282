import { RouteProps } from 'react-router-dom';
import { authPagesMenu, pageLayoutTypesPagesMenu } from '../menu';

const footers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: authPagesMenu.login.path, element: null },
	{ path: authPagesMenu.signUp.path, element: null },
	{ path: authPagesMenu.page404.path, element: null },
];

export default footers;
