import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BusinessData } from '../common/data/business-data';

export interface IBusinessContextProps {
	business: BusinessData;
	setBusiness({}: BusinessData): void;
	businessList: BusinessData[];
	setBusinessList({}: BusinessData[]): void;
}
const BusinessContext = createContext<IBusinessContextProps>({} as IBusinessContextProps);

interface IBusinessContextProviderProps {
	children: ReactNode;
}
export const BusinessContextProvider: FC<IBusinessContextProviderProps> = ({ children }) => {
	const businessStoredData = localStorage.getItem('mudavim_businessData');
	const [business, setBusiness] = useState<BusinessData>(
		businessStoredData ? JSON.parse(businessStoredData) : {},
	);

	const businessListStoredData = localStorage.getItem('mudavim_businessListData');
	const [businessList, setBusinessList] = useState<BusinessData[]>(
		businessListStoredData ? JSON.parse(businessListStoredData) : [],
	);

	useEffect(() => {
		localStorage.setItem('mudavim_businessData', JSON.stringify(business));
	}, [business]);

	useEffect(() => {
		localStorage.setItem('mudavim_businessListData', JSON.stringify(businessList));
	}, [businessList]);

	const value = useMemo(
		() => ({
			business,
			setBusiness,
			businessList,
			setBusinessList,
		}),
		[business, businessList],
	);
	return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
};
BusinessContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default BusinessContext;
