import React, { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import AuthContext from '../../../contexts/authContext';
import BusinessContext from '../../../contexts/businessContext';

const DashboardHeader = () => {
	const { i18n } = useTranslation();

	const { business } = useContext(BusinessContext);

	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<Header>
			<HeaderLeft>
				<div className='user-header'>
					<div className='user-avatar'>
						<img src={business?.logo} alt='Avatar' width={128} height={128} />
					</div>
					<div>
						<div className='user-info'>
							<div className='user-name'>{business?.name}</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
		</Header>
	);
};

export default DashboardHeader;
