import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import firebaseConfig from './firebase/config';
import { BusinessContextProvider } from './contexts/businessContext';

const children = (
	<AuthContextProvider>
		<BusinessContextProvider>
			<ThemeContextProvider>
				<Router>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</Router>
			</ThemeContextProvider>
		</BusinessContextProvider>
	</AuthContextProvider>
);

initializeApp(firebaseConfig);

const container = document.getElementById('root');

createRoot(container as Element).render(children);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
